




































// library
import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
// types
import { FormWrapperRequest } from '@/types/form';
// components
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ListDeleteModal',
  components: {
    Btn,
    AppModal,
    ApiError,
  },
  mixins: [formSubmit],
  props: {
    title: {
      type: String,
      default: 'Delete',
    },
    // The resource type - project, user, role, etc
    resource: {
      type: String,
      default: 'resource',
    },
    url: {
      type: String,
      required: true,
    },
    ids: {
      type: Array,
      required: true,
    },
  },
  computed: {
    deleteBtnLabel(): string {
      const plural = this.ids.length === 1 ? '' : 's';
      return `Delete ${this.ids.length} ${this.resource}${plural}`;
    },
    request(): FormWrapperRequest {
      return {
        config: {
          method: 'put',
          url: this.url,
          data: { ids: this.ids },
        },
      };
    },
  },
  methods: {
    async onSubmit() {
      this.error = '';

      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }

      this.notify(`Selected ${this.resource}s deleted`);
      this.$emit('deleted');
      if (this.$refs.modal) {
        (this.$refs.modal as any).close();
      }
    },
  },
});
