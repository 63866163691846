



































































































import Vue, { PropType } from 'vue';
import { RequestConfig, SearchResponse, TableColumn } from '@/types/list';
import List from '@/components/list/List.vue';
import ListFilter from '@/components/list/ListFilter.vue';
import ListDeleteModal from '@/components/list/ListDeleteModal.vue';
import AppCard from '@/components/card/AppCard.vue';
import ListSearch from '@/components/list/ListSearch.vue';
import ExportBtn from '@/components/list/ExportBtn.vue';

export default Vue.extend({
  name: 'ListWithFilter',
  components: {
    ListSearch,
    AppCard,
    List,
    ListFilter,
    ListDeleteModal,
    ExportBtn,
  },
  props: {
    listTitle: String,
    name: {
      type: String,
      required: true,
    },
    resource: {
      type: String,
    },
    labelField: {
      type: String,
      default: 'label',
    },
    colLabel: {
      type: String,
      default: 'Name',
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    extraColumns: {
      type: Array as PropType<TableColumn[]>,
      required: false,
      default() {
        return [];
      },
    },
    withDatetime: Boolean,
    withRowActions: Boolean,
    withCreatedBy: {
      type: Boolean,
      default: true,
    },
    withUpdatedAt: Boolean,
    searchUrl: {
      type: String,
      required: true,
    },
    viewUrl: {
      type: String,
      required: false,
    },
    deleteUrl: {
      type: String,
      required: false,
    },
    exportUrl: {
      type: String,
      required: false,
    },
  },
  computed: {
    withSelectableRows(): boolean {
      return !!this.deleteUrl || !!this.$scopedSlots.deleteModal;
    },
    applyFilter(): (filter: Record<string, any>) => void {
      const { filter } = this.$refs;
      if (filter) {
        return (filter as any).applyFilter;
      }
      return () => {};
    },
  },
  data() {
    return {
      showFilters: false,
      requestConfig: {
        url: this.searchUrl,
      } as RequestConfig,
      selectedRows: [] as string[],
      totalRows: 0,
    };
  },
  methods: {
    async onSearch(val: string) {
      if (this.requestConfig.params) {
        this.requestConfig.params.search = val;
        await this.loadRows();
      }
    },
    async onRowClick(row: { id: string }) {
      if (this.viewUrl) {
        await this.$router.push(`${this.viewUrl}/${row.id}`);
      }
      this.$emit('rowClick', row);
    },
    loadRows() {
      (this.$refs.list as any).loadRows();
    },
    onFilter(filter: any) {
      this.requestConfig.params = filter;
      this.loadRows();
    },
    setFilterData(data: SearchResponse) {
      this.selectedRows = [];

      this.totalRows = data.existing || data.total;
      this.$nextTick(() => {
        if (this.totalRows) {
          (this.$refs.filter as any).setCreators(data.creators);
        }
      });
    },
    async onBatchDelete() {
      this.selectedRows = [];
      await this.loadRows();
    },
    clearFilter() {
      const { filter } = this.$refs;
      if (filter) {
        (filter as any).clearFilter();
      }
    },
  },
  created() {
    this.requestConfig = {
      url: this.searchUrl,
      params: {
        search: '',
      },
    };
  },
});
