<template>
  <div class="row q-pa-md">
    <div class="col-3 q-pr-md">
      <ListSearch v-model="filter.search" @input="applyFilter"/>
      <slot name="extraFilters"></slot>
    </div>

    <div class="col-3 q-pr-md">
      <q-select
        dense
        outlined
        multiple
        clearable
        emit-value
        map-options
        no-error-icon
        v-model="filter.creators"
        @input="applyFilter"
        :options="creators"
        label="Created by"
        debounce="300"
      >
        <template #no-option>
          <slot name="no-option">
            <q-item>
              <q-item-section>
                <q-item-label>No results</q-item-label>
              </q-item-section>
            </q-item>
          </slot>
        </template>
      </q-select>
    </div>
    <div class="col-3 q-pr-md">
      <FieldDate
        label="From"
        v-model="filter.dateFrom"
        @input="applyFilter"
      />
    </div>
    <div class="col-3">
      <FieldDate
        label="To"
        v-model="filter.dateTo"
        @input="applyFilter"
      />
    </div>
  </div>
</template>

<script>
import FieldDate from '@/components/fields/FieldDate.vue';
import ListSearch from '@/components/list/ListSearch.vue';
import { getTimestampFromDateString } from '@/util/date';

function getDefaultFilter() {
  return {
    search: '',
    creators: [],
    dateFrom: null,
    dateTo: null,
  };
}

export default {
  name: 'ListFilter',
  components: {
    ListSearch,
    FieldDate,
  },
  props: {
    extraFilters: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      filter: getDefaultFilter(),
      creators: [],
    };
  },
  methods: {
    applyFilter(val, externalFilter = {}) {
      const filter = { ...this.filter, ...externalFilter };
      if (filter.creators?.length) {
        filter.creators = filter.creators.join(',');
      }

      if (filter.dateTo && typeof filter.dateTo === 'string') {
        filter.dateTo = getTimestampFromDateString(filter.dateTo, true);
      }
      if (filter.dateFrom && typeof filter.dateFrom === 'string') {
        filter.dateFrom = getTimestampFromDateString(filter.dateFrom);
      }

      this.$emit('filter', filter);
    },
    clearFilter() {
      this.filter = getDefaultFilter();
      this.applyFilter();
    },
    setCreators(creators) {
      if (typeof creators === 'object') {
        this.creators = Object.keys(creators).map((userId) => ({
          value: userId,
          label: creators[userId].displayName,
        }));
      } else {
        this.creators = [];
      }
    },
  },
};
</script>
