














import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    // avoid searching for one or less letters of query to avoid unnecessary calls to the backend
    handleInput(input: string) {
      if (input && input.length < 2) {
        this.$emit('input', '');
        return;
      }
      this.$emit('input', input || '');
    },
  },
});
